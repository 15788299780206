import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { FaCheckCircle, FaChevronRight, FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import Button from '../../components/button/Button';
import Fab from '../../components/fab/Fab';
import Input from '../../components/input/Input';
import Modal from '../../components/modal/Modal';
import { createTask, deleteTask, getTasks, updateTask } from '../../resources/api/task';
import './TasksPage.scss';

class TasksPage extends React.Component {
    constructor() {
        super()
        this.state = {
            newTaskModal: false,
            showCompleted: false,
            tasks: [],
            task: {},
            selected: 0,
        }
    }

    componentDidMount() {
        this.getTasks()
    }

    getTasks(){
        getTasks().then(res => this.setState({ tasks: res })).catch()
    }

    toggle(task){
        console.log("Toggle:",task)
        updateTask({id:task.id,done:!task.done}).then(res => {
            this.getTasks()
        })
    }

    createTask(data){
        createTask(data).then(res=>{
            var tasks = this.state.tasks
            tasks.push(res)
            this.setState({tasks:tasks})
        }).catch(err=>{
            console.log(err)
        }).finally(()=>{
            this.setState({selected:0, task:{}})
        })
    }

    updateTask(data){
        updateTask(data).then(res=>{
            var tasks = [...this.state.tasks]
            for(let i in tasks){
                if(tasks[i].id===res.id){
                    for(let j in res){
                        tasks[i][j] = res[j]
                    }
                }
            }
            this.setState({tasks:tasks})
        }).catch(err=>{
            console.log(err)
        }).finally(()=>{
            this.setState({selected:0, task:{}})
        })
    }

    deleteTask(id){
        this.setState({selected:0, task:{}})
        deleteTask({id:id}).then(res =>{
            var tasks = [...this.state.tasks]
            for(let i in tasks){
                if(tasks[i].id === id){
                    tasks.splice(i,1)
                    break;
                }
            }
            this.setState({tasks:tasks})
        }).catch(err=>{
            console.log(err)
        })
    }

    render() {
        return <div className="page" id="tasks-page">
            <main>
                <h1>Tasks</h1>

                <div className="tasks">
                    <div className="not-completed">
                        {!this.state.tasks.filter(i => !i.done).length&&<div className="all-done">All done!</div>}
                        {this.state.tasks.filter(i => !i.done).map(task => 
                            <TaskRowCard 
                                key={task.id} {...task} 
                                toggle={() => this.toggle(task)} 
                                onClick={() => this.setState({selected: task.id})}    
                            />)}
                    </div>

                    <div className={"completed-toggle "+(this.state.showCompleted?"rotate":"")} onClick={() => this.setState({showCompleted:!this.state.showCompleted})}>
                        <FaChevronRight/> Completed Tasks
                    </div>
                    {this.state.showCompleted&&<div className="completed">
                        {this.state.tasks.filter(i => i.done).map(task => 
                            <TaskRowCard 
                                key={task.id} {...task} 
                                toggle={() => this.toggle(task)} 
                                onClick={() => this.setState({selected: task.id})}    
                            />)}
                    </div>}
                </div>

                <Fab onClick={() => this.setState({ selected: -1 })}>
                    <BiPlus />
                </Fab>

                <Modal title={this.state.selected>0?"Edit task":"New task"} open={this.state.selected} close={() => this.setState({ selected: 0 })}>
                    {!!this.state.selected&&
                        <TaskDetail 
                            task={this.state.tasks.filter(i => i.id===this.state.selected)[0]}
                            onChange={values=>this.setState({task:values})}
                        />
                    }
                    <div className="button">
                        <Button onClick={()=>this.deleteTask(this.state.selected)}>
                            <FaTrashAlt/>
                        </Button>
                        <Button variant="full"
                            onClick={this.state.selected>0?
                                ()=>this.updateTask(this.state.task):
                                ()=>this.createTask(this.state.task)
                            }
                        > {this.state.selected>0?"Save":"Add"} </Button>
                    </div>                    
                </Modal>
            </main>
        </div>
    }
}

/**
 * ()=>{
    createTask(this.state.newTask).then(res => {
        this.setState({newTask:{title:""}, newTaskModal:false});
        this.getTasks()
    }).catch(err => {
        this.setState({error: err})
    }); 
    
}
 */


class TaskDetail extends React.Component{
    constructor(props){
        super(props)
        this.state={
            task:{
                subtasks:[],
                ...props.task
            },
            newSubtask:""
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.task!==this.state.task){
            if(this.props.onChange)
            this.props.onChange(this.state.task)
            return
        }
        if(prevProps.task!==this.props.task){
            this.setState({task:this.props.task})
            return
        }
    }

    updateValue(name, value){
        this.setState({
            task:{
                ...this.state.task,
                [name]:value,
            }
        })
    }

    updateSubtask(id,data){
        var subtasks = [...this.state.task.subtasks]
        
        if(!data) subtasks.splice(id,1)
        else if(id===-1)subtasks.push(data)
        else
        subtasks.forEach((subtask,k) => {
            if(id === k){
                for(let key in data){
                    subtask[key]=data[key]
                }
            }
        })

        this.setState({
            task: {
                ...this.state.task,
                subtasks: subtasks
            }
        })
    }

    render(){
        return <div className="task-detail">
            <div className="title">
                <Input 
                    placeholder="Task title ..." 
                    value={this.state.task.title} 
                    onChange={e=>this.updateValue("title",e.target.value)}
                />
            </div>
            
            {<p>Subtasks
                <div className="subtasks">
                    {this.state.task.subtasks.map((i,k) => 
                        <div className={"subtask " +(i.done?"done":"")}>
                            <div className={"icon status"}
                                onClick={()=>this.updateSubtask(k,{done:!i.done})}
                            ><FaCheckCircle/></div>
                            <div className="input"><input value={i.title} onChange={e=>this.updateSubtask(k, {title:e.target.value})}/></div>
                            <div className="icon"
                                onClick={()=>this.updateSubtask(k,false)}
                            ><FaTrashAlt/></div>
                        </div>
                    )}
                    <div className="subtask">
                        <div className="icon"><FaPlusCircle
                            onClick={()=>{
                                this.updateSubtask(-1,{title:this.state.newSubtask})
                                this.setState({newSubtask:""})
                            }}
                        /></div>
                        <div className="input"><input 
                            value={this.state.newSubtask} 
                            onChange={e=>this.setState({newSubtask:e.target.value})}
                            placeholder="Subtask ..."
                        /></div>
                    </div>
                </div>
            </p>}
        </div>
    }
}



function TaskRowCard({ title, done, id, tags=[], toggle=()=>{}, onClick=()=>{} }) {
    return <div className="comp-task-row-card">
        <div className={"status "+(done?"done":"")} onClick={() => toggle()}> 
            <FaCheckCircle/>
        </div>
        <div className="title" onClick={() => onClick()}>{title}</div>
        <div className="tags">
            {tags.map(tag => <div className="tag" style={{backgroundColor:tag.color}} key={tag.id}>{tag.name}</div>)}
        </div>
    </div>
}

export default TasksPage;