import {strapi} from '../strapi';

function mapTask(task={}){
    return {
        id: task.id || 0,
        title: task.title || "",
        tags: task.tags || [],
        done: task.done || false,
        subtasks: task.subtasks || [],
        details: task.details || "",
        files: task.files || [],
    }
}

export async function getTasks(){
    return (await strapi.getEntries("tasks").catch(err => console.log(err))).map(task => mapTask(task));
}

export async function createTask(data){
    const res = await strapi.createEntry("tasks",data).catch(err => console.log(err))
    return mapTask(res);
}

export async function updateTask(data){
    const res = await strapi.updateEntry("tasks",data.id,data).catch(err => console.log(err))
    return mapTask(res);
}

export async function deleteTask({id}){
    const res = await strapi.deleteEntry("tasks",id).catch(err => console.log(err))
    return res
}