import React from 'react'
import './App.scss';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import TasksPage from './pages/tasks/TasksPage';
import LoginPage from './pages/LoginPage';
import { strapi } from './resources/strapi';

export default class App extends React.Component{
    constructor(){
        super()
        this.state = {
            logged: false
        }
    }

    componentDidMount(){
        if(strapi.currentUser)
        strapi.request("GET","/users/me").then(res=>{
            this.setState({logged:true})
        }).catch(err => {
            
        })
    }

    login(res){
        this.setState({logged: true})
    }

    logout(){
        this.setState({logged:false})
        strapi.clearToken()
    }

    render(){
        return <div id="app" className="dark-theme">
            {this.state.logged?
                <BrowserRouter>
                    <Switch>
                        <Route path="/" render={() => <TasksPage/>}/>
                    </Switch>
                </BrowserRouter>:
                <LoginPage login={(x) => this.login(x)}/>
            }
        </div>
    }
} 