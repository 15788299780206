import React from 'react';
import { IoClose } from 'react-icons/io5'
import './Modal.scss';

export default class Modal extends React.Component{
    render(){
        const {open, title, children=[], close=()=>{}} = this.props

        return <div className={"comp-modal "+(open?"open":"")}>
            <div className="top">
                <div className="title">
                    {title}
                </div>
                <div className="close" onClick={() => close()}>
                    <IoClose/>
                </div>
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    }
}