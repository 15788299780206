import React from 'react';
import './Button.scss';

/**
 * Button component
 * 
 * @param {string} variant button variant ["full", ""]
 * @param {*} children 
 * all props are passed to child
 */
export default class Button extends React.Component{
    constructor(){
        super()
        this.state = {
            animate: false,
        }
    }

    render(){
        return <div className={"comp-btn "+(this.props.variant?this.props.variant:"")+" "+(this.state.animate&&"animate")} {...this.props} onClick={
            () => {
                if(this.props.onClick)this.props.onClick()
                if(!this.state.animate)
                {
                    this.setState({animate:true});
                    setTimeout(() => this.setState({animate:false}),300)
                }
                
            }
        }>
            {this.props.children}
        </div>
    }
}