import React from 'react';
import { strapi } from '../resources/strapi';

export default class LoginPage extends React.Component{
    constructor(){
        super()
        this.state = {
            identifier: "",
            password:"",
            error:"",
        }
    }

    login(){
        strapi.login(this.state.identifier, this.state.password).then(res => {
            this.setState({error: false})
            this.props.login(res)
        }).catch(err => {
            this.setState({error: err.data?.error})
        })
    }

    render(){
        return <div className="page" id="login">
            <main>
                {this.state.error&&<>{this.state.error}<br/></>}
                <input type="text" placeholder="identifier" value={this.state.identifier} onChange={e => this.setState({identifier: e.target.value})}/><br/>
                <input type="password" placeholder="password" value={this.state.password} onChange={e => this.setState({password: e.target.value})}/><br/>
                <input type="submit" value="Log in" onClick={() => this.login()}/>
            </main>
        </div>
    }
}