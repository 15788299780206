import React from 'react';
import './Input.scss';

/**
 * Input component
 * @param {string} label Input label
 * all props are passed to the input element
 */
export default function Input(props){
    const {invalid, label, ...p} = props
    return <div className={"inputWrapper "+(invalid?"invalid ":"")}>
        <input {...{placeholder:" ",...p}} className={(label?"":"nolabel ")}/>
        {label&&<div className="label">{label}</div>}
    </div>
}